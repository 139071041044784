import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { Button } from "../components/button"

import Logo from "../components/logo"
import LoginLayout from "../components/login-layout"
import Seo from "../components/seo"
import SplashImage from "../components/splash-image"

function LoginPage() {
  const [showMessage, setShowMessage] = useState(false)
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1))

    if (params.has("success")) {
      setShowMessage(true)
    }
  }, [])

  return (
    <LoginLayout>
      <Seo title="Log In" />

      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <SplashImage />
        </div>

        <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
          <div className="w-full h-100">
            <Link to="/">
              <div className="flex flex-shrink-0 items-center">
                <Logo />
                <h1 className="serif xl:text-xl font-bold">
                  British Society{" "}
                  <i className="font-normal text-gray-700">of</i>{" "}
                  <span className="block">Skeletal Radiologists</span>
                </h1>
              </div>
            </Link>

            <h2 className="text-xl md:text-2xl font-bold leading-tight mt-12 mb-6">
              Welcome to the BSSR Website
            </h2>

            {showMessage && (
              <p className="my-6 bg-green-300 border-green-500 border-2 rounded p-4">
                Congratulations your online account was created successfully. To
                complete your membership please click on the button below to
                sign into your online account.
              </p>
            )}

            {!showMessage && (
              <p className="mb-6">
                Sign into your online account to manage your membership and
                search for other BSSR members.
              </p>
            )}

            {/* <LoginForm /> */}

            <Button onClick={() => loginWithRedirect()} className="w-full">
              Click Here to Sign Into Your Account
            </Button>

            <Link
              to="/reset-password"
              className="text-indigo-700 font-semibold my-4 mt-8 block"
            >
              Forgot your password?
            </Link>

            <hr className="my-6 border-gray-300 w-full" />

            <p className="my-4">
              Need an account?{" "}
              <Link to="/register" className="text-indigo-700 font-semibold">
                Create an account
              </Link>
            </p>
          </div>
        </div>
      </section>
    </LoginLayout>
  )
}

export default LoginPage
